import React, { createContext, useState } from 'react'
import type { ReactNode } from 'react'
import { navigate } from 'gatsby'

interface IQuizContext {
  step: number
  answers: {
    email: string
    name: string
    skin: 'Claro' | 'Médio Claro' | 'Médio' | 'Médio Escuro' | 'Escuro' | ''
    nude: 'Nude Natural' | 'Nude Uau' | ''
  }
  documentId: string
  nextQuestion: () => void
  backQuestion: () => void
  setAnswers: React.Dispatch<
    React.SetStateAction<{
      email: string
      name: string
      skin: 'Claro' | 'Médio Claro' | 'Médio' | 'Médio Escuro' | 'Escuro' | ''
      nude: 'Nude Natural' | 'Nude Uau' | ''
    }>
  >
  setDocumentId: React.Dispatch<React.SetStateAction<string>>
}

export const QuizContext = createContext({} as IQuizContext)

export const QuizContextProvider = ({ children }: { children: ReactNode }) => {
  const [step, setStep] = useState(0)
  const [documentId, setDocumentId] = useState('')
  const [answers, setAnswers] = useState<{
    email: string
    name: string
    skin: 'Claro' | 'Médio Claro' | 'Médio' | 'Médio Escuro' | 'Escuro' | ''
    nude: 'Nude Natural' | 'Nude Uau' | ''
  }>({
    email: '',
    name: '',
    skin: '',
    nude: '',
  })

  const nextQuestion = () => setStep(step + 1)

  const backQuestion = () => {
    if (step > 0) {
      setStep(step - 1)
    }

    if (step === 0) {
      navigate('/')
    }
  }

  return (
    <QuizContext.Provider
      value={{
        step,
        nextQuestion,
        backQuestion,
        answers,
        setAnswers,
        documentId,
        setDocumentId,
      }}
    >
      {children}
    </QuizContext.Provider>
  )
}
