import React, { useContext } from 'react'
import { Box, Flex, Heading } from '@vtex/store-ui'

import styles from './styles.json'
import { QuizContext } from '../context/QuizContext'

export const Header = () => {
  const { backQuestion, step } = useContext(QuizContext)

  return (
    <Flex
      sx={{
        ...styles.container,
        '&::before': {
          content: "''",
          position: 'absolute',
          top: '78px',
          left: '0',
          width: `calc(25% * ${step + 1})`,
          height: '3px',
          backgroundColor: '#C88598',
          transition: 'width 0.5s ease',
        },
      }}
    >
      <Box onClick={backQuestion} sx={styles.backButton}>
        <svg
          version="1.1"
          id="Camada_1_arrow-right"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 19.6 31.7"
        >
          <g transform="translate(7.41) rotate(90)">
            <path d="M28,7.3l-12-12l-12,12L0.2,3.6l15.7-15.7L31.7,3.6L28,7.3z" />
          </g>
        </svg>
        <span>{step === 0 ? 'Ir para Home' : 'Voltar'}</span>
      </Box>
      <Heading as="h1" sx={styles.title}>
        Batom Nude Perfeito
      </Heading>
    </Flex>
  )
}
