import React, { useEffect, useRef, useContext, useState } from 'react'
import type { FormEvent } from 'react'
import { Box, Button, Flex, Image, Input, SuspenseDevice } from '@vtex/store-ui'
import { useToast, useProfile } from '@vtex/gatsby-theme-store'
import { isValidEmail } from 'src/utils'

import { QuizContext } from '../../context/QuizContext'
import styles from './styles.json'

interface EmailQuestionProps {
  image: {
    props: {
      desktop: {
        srcSet: string
      }
      alt: string
    }
  }
}

export const EmailQuestion = ({ image }: EmailQuestionProps) => {
  const { nextQuestion, setAnswers, documentId, setDocumentId } =
    useContext(QuizContext)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emailUserActive, setEmailUserActive] = useState(false)
  const inputEmailRef = useRef<HTMLInputElement>(null)
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  const { showToast } = useToast()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isAuthenticated) {
        const userEmail = profile?.email?.value
        const requestUrl = `/api/io/safedata/CL/search?_where=email=${userEmail}&_fields=id`
        const options = {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }

        if (userEmail && inputEmailRef?.current) {
          inputEmailRef.current.value = userEmail
          setEmailUserActive(true)
          setEmail(userEmail)

          fetch(requestUrl, options)
            .then((res) => res.json())
            .then((data: Array<{ id: string }>) => {
              setDocumentId(data[0].id)
            })
            .catch((error) => console.error('Error while get data', error))
        }
      }
    }
  }, [documentId, isAuthenticated, profile?.email?.value, setDocumentId])

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault()

    if (isValidEmail(email)) {
      setAnswers((state) => ({ ...state, email, name }))

      nextQuestion()
    }

    if (!isValidEmail(email)) {
      showToast({
        content: 'Email inválido. Digite um email válido',
        type: 'error',
        id: 'mobile-add-to-cart',
      })
    }
  }

  return (
    <>
      <Box sx={{ ...styles.imageContainer, display: ['none', 'flex'] }}>
        <Box>
          <Image src={image.props.desktop.srcSet} alt={image.props.alt} />
        </Box>
      </Box>
      <Box sx={styles.formContainer}>
        <span className="step">Passo 1 de 4</span>
        <h2 className="question">Qual o seu nome?</h2>
        <Box as="form" onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Email"
            ref={inputEmailRef}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!emailUserActive}
            required
          />
          <Input
            type="text"
            placeholder="Nome"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Flex sx={styles.formContainer.checkbox}>
            <Input type="checkbox" id="privacyPolicy" required />
            <label htmlFor="privacyPolicy">
              Aceito os termos de política de privacidade
            </label>
          </Flex>
          <Button>Próximo</Button>
        </Box>
      </Box>
      <SuspenseDevice device="mobile" fallback={null}>
        <Flex sx={styles.imageContainer}>
          <Box>
            <Image src={image.props.desktop.srcSet} alt={image.props.alt} />
          </Box>
        </Flex>
      </SuspenseDevice>
    </>
  )
}
