import React from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { getCookie } from 'src/utils/cookies'
import { useSiteLinksSearchBoxJsonLd } from 'src/views/home/Seo/useSiteLinksSearchBoxJsonLd'
import type { PageProps } from 'gatsby'
import type { QuizRenewQueryQuery } from 'src/pages/quiz-antissinais/__generated__/QuizRenewQuery.graphql'

type Props = PageProps<QuizRenewQueryQuery>

const Seo: FC<Props> = (props) => {
  const siteMetadata =
    props?.data?.cmsInstitutionalPage?.seo?.siteMetadataWithSlug

  const canonicalPathname = `https://www.avon.com.br/${siteMetadata?.slug}`
    .replace('.br///', '.br/')
    .replace('.br//', '.br/')

  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)

  const repId = getCookie('selectedRepresentantId')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalPathname} />
      </Helmet>
      <GatsbySeo
        title={siteMetadata?.title ?? 'Quiz Renew'}
        description={siteMetadata?.description ?? 'Quiz Renew'}
        titleTemplate={siteMetadata?.titleTemplate ?? '%s | Avon'}
        noindex={!!repId}
      />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo
