import React from 'react'
import { Box, Flex, SuspenseSSR } from '@vtex/store-ui'
import { Header } from 'src/components/quiz-batons/Header'
import { QuizContextProvider } from 'src/components/quiz-batons/context/QuizContext'
import Questions from 'src/components/quiz-batons/Questions'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import background from './assets/quiz-batons-bg.png'
import backgroundMobile from './assets/quiz-batons-bg-mobile.png'

interface Props {
  data: {
    cmsInstitutionalPage: {
      sections: Array<{
        allItems: []
        props: {
          allItems: []
          desktop: {
            srcSet: string
          }
          alt: string
        }
      }>
    }
  }
}

export const AboveTheFold = ({ data }: Props) => {
  const { isMobile } = useDeviceDetect()

  return (
    <QuizContextProvider>
      <Box
        sx={{
          background: `url(${
            isMobile ? backgroundMobile : background
          }) no-repeat 50% fixed;`,
          backgroundSize: 'cover',
          fontFamily: 'Zona Pro, sans-serif',
          mr: ['0', '0'],
        }}
      >
        <Header />
        <Flex
          sx={{
            minHeight: ['670px'],
            height: ['auto', 'calc(100vh - 80px)'],
            alignItems: ['center'],
            flexDirection: ['column', 'row'],

            'span.step': {
              color: '#b2bbbf',
              fontSize: '0.875rem',
              fontWeight: '700',
            },

            'h2.question': {
              mt: '0.3125rem',
              mb: ['1.25rem', '2.5rem'],
              lineHeight: ['1.625rem', '3rem'],
              fontWeight: '700',
              color: '#f7d29d',
            },
          }}
        >
          <SuspenseSSR fallback={null}>
            <Questions data={data} />
          </SuspenseSSR>
        </Flex>
      </Box>
    </QuizContextProvider>
  )
}
