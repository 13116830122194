import { graphql } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import { AboveTheFold } from 'src/views/quiz-batons/AboveTheFold'
import Seo from 'src/views/quiz-batons/Seo'

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
}

const Page: FC = (props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf__bwUAAAAAAcD3DIJoQVhv8uMTkP08A32pHXW">
      <Layout hideHeaderAndFooter>
        <View {...ViewComponents} data={props} />
      </Layout>
    </GoogleReCaptchaProvider>
  )
}

export const querySSG = graphql`
  query QuizBatonsQuery {
    cmsInstitutionalPage(name: { eq: "Quiz Batons" }) {
      sections {
        name
        props: data
      }
      seo {
        siteMetadataWithSlug {
          title
          description
          titleTemplate
          slug
        }
      }
    }
  }
`

export default Page
