/* eslint-disable */
import React, { lazy, useContext } from 'react'
import { SuspenseSSR } from '@vtex/store-ui'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'

import { QuizContext } from '../context/QuizContext'
import { EmailQuestion } from './EmailQuestion'

const SkinQuestion = lazy(() => import('./SkinQuestion'))
const LipstickQuestion = lazy(() => import('./LipstickQuestion'))
const Result = lazy(() => import('./Result'))

interface Props {
  data: {
    cmsInstitutionalPage: {
      sections: Array<{
        allItems: Array<{ sources: [{ srcSet: string }]; alt: string }>
        props: {
          allItems: []
          desktop: {
            srcSet: string
          }
          alt: string
        }
      }>
    }
  }
}

const Questions = ({ data }: Props) => {
  const { step } = useContext(QuizContext)
  const { setIsLoginModalOpen } = useLoginModal()
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  if (!isAuthenticated) {
    setIsLoginModalOpen(true)
  }

  return (
    <>
      {step === 0 && (
        <EmailQuestion image={data.cmsInstitutionalPage.sections[0]} />
      )}
      <SuspenseSSR fallback={null}>
        {step === 1 && (
          <SkinQuestion
            images={data.cmsInstitutionalPage.sections[1].props.allItems}
          />
        )}
        {step === 2 && (
          <LipstickQuestion
            images={data.cmsInstitutionalPage.sections[2].props.allItems}
          />
        )}
        {step === 3 && <Result />}
      </SuspenseSSR>
    </>
  )
}

export default Questions
